import React from "react";
// import PageRouter from "./router/PageRouter";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

function App() {
	useEffect(() => {
		// eslint-disable-next-line no-undef
		window.location.href = "https://cyberhelpinfo.com";
		AOS.init();
		// eslint-disable-next-line no-undef
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{/* <PageRouter /> */}
			<CircularProgress sx={{ color: "#000" }} />
		</>
	);
}

export default App;
